<template>
  <div class="product-details">
    <div class="product-details__brand" v-html="product.brand"></div>
    <h1 class="product-details__name" v-html="product.name"></h1>

    <div class="product-details__rating">
      <div class="product-rating" v-if="product.rating > 0 && product.ratingCount > 0">
        <span class="product-rating__label">Valoración global:</span>
        <span class="label-rating">
          <no-ssr>
            <star-rating
              :inline="true"
              :star-size="20"
              :read-only="true"
              :show-rating="false"
              :rating="product.rating"
              :round-start-rating="false"
            ></star-rating>
          </no-ssr>
        </span>
        <span class="product-rating__totals">(sobre {{ totalRatings }})</span>

        <button
          class="product-rating__arrow"
          aria-label="Ver valoraciones"
          @click="rating = !rating"
          :class="rating ? 'product-rating__arrow--open' : ''"
        >
          <img src="../../../assets/img/ico/ico-chevron-up.svg" loading="lazy" alt="" />
        </button>
      </div>
    </div>

    <transition name="rating">
      <div class="list-product-retailers" v-if="rating">
        <div
          class="list-product-retailers__item"
          v-for="(retailer, index) in productRetailers"
          :key="index"
        >
          <span class="list-product-retailers__name">
            {{ retailer.name }}
          </span>
          <span class="list-product-retailers__rating">
            <no-ssr>
              <star-rating
                :inline="true"
                :star-size="20"
                :read-only="true"
                :show-rating="false"
                :rating="retailer.rating"
                :round-start-rating="false"
              ></star-rating>
            </no-ssr>
            <span class="list-product-retailers__sobre">
              (sobre {{ retailer.ratingsTotal }})
            </span>
          </span>
          <a
            :href="retailer.url"
            target="_blank"
            @click="mixPanel(retailer)"
            class="list-product-retailers__link"
          >
            Ver
          </a>
        </div>
      </div>
    </transition>

    <ProductOptions v-if="product.options" :product="product"></ProductOptions>
    <div v-else class="product-price">
      <span class="product-price__range">
        {{ textPrice }}
      </span>
    </div>

    <div class="product-actions">
      <div class="product-actions__list">
        <product-add-to-list-button
          :product="product"
          :source="source"
          :isHero="isHero"
        />
        <no-ssr>
          <div
            class="product-actions__info"
            v-tooltip="{
              html: true,
              content:
                'Crea tu lista de nacimiento con cosas de cualquier tienda usando HelloBB.',
              handleResize: false,
              triggers: ['hover', 'focus', 'click'],
              // placement: 'auto-end',
            }"
          >
            <i class="uil uil-info-circle"></i>
          </div>
        </no-ssr>
      </div>

      <div v-if="product.shopifyId" class="product-actions__shop">
        <shopify-add-to-cart-button
          :product="product"
          :source="source"
          @opening="sendMixPanelInfo()"
        ></shopify-add-to-cart-button>
        <div class="ecom-add-tips">
          <div class="ecom-add-tips__item">Envíos gratis para pedidos de +50€.</div>
        </div>
      </div>
    </div>
    <div class="product-details__ofertas" v-if="moreVendorsBesidesHelloBB">
      <aside class="regalo-shops">
        <h5 class="regalo-shops__title regalo-shops__title--catalog">
          <template v-if="retailerIsHelloBB"> También lo puedes encontrar en: </template>
          <template v-else> Mejores ofertas detectadas: </template>
        </h5>
        <!-- show disclaimer only if productRetailers contains Amazon -->
        <div class="regalo-shops__disclaimer" v-if="productRetailersName.filter(x => x == 'Amazon').length > 0">
          <template v-if="productRetailers.length == 1">Enlace afiliado.</template>
          <template v-else>Enlaces afiliados.</template>
        </div>
        <div
          class="regalo-shops__item"
          v-for="(retailer, index) in productRetailers"
          :key="index"
        >
          <div class="regalo-shops__shop">
            <img
              :src="$getLogoRetalier(retailer.name, retailer)"
              width="30"
              height="30"
              alt
              class=""
            />
            <span>{{ retailer.name }}</span>
          </div>
          <div class="regalo-shops__price regalo-shops__price--catalog">
            {{ $utils.formatMoney(getPrice(retailer)) }}
          </div>
          <div class="regalo-shops__button">
            <a
              class="button button--sm button--ico"
              :class="
                notHighlightSecundaryBuyButton
                  ? 'button--outline-dark'
                  : 'button--primary'
              "
              @click.stop="goToRetailer(retailer)"
              target="_blank"
              rel="nofollow"
            >
              <i class="uil uil-external-link-alt"></i> {{ $t("generic.buy") }}
            </a>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NoSSR from "vue-no-ssr";
import ProductAddToListButton from "./ProductAddToListButton.vue";
import StarRating from "vue-star-rating";
import ProductOptions from "./ProductOptions.vue";
import ShopifyAddToCartButton from "../../shared/ShopifyAddToCartButton";
import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";
import { selectedVariant } from "../../../store/getters";

export default {
  components: {
    StarRating,
    "no-ssr": NoSSR,
    ProductAddToListButton,
    ShopifyAddToCartButton,
    ProductOptions,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
    },
    isHero: {
      type: Boolean,
    },
  },
  data() {
    return {
      rating: false,
      checkedRetailer: null,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      isAuthenticated: "isAuthenticated",
      user: "user",
      selectedVariant: "selectedVariant",
    }),
    differentPricing() {
      return this.product.minPrice !== this.product.maxPrice;
    },
    totalRatings() {
      let total = 0;
      this.product.productRetailers?.forEach((element) => {
        total += element.ratingsTotal;
      });
      return total;
    },
    retailerLink() {
      return this.checkedRetailer?.url;
    },
    retailerIsHelloBB() {
      if (this.product.shopifyId) return true;
      if (this.product.productRetailers?.length > 0) {
        return this.product.productRetailers[0]?.name === "HelloBB";
      }
      return false;
    },
    productRetailersName() {
      return this.product.productRetailers.map(function (item) {
        return item["name"];
      });
    },
    SoldByHelloBB() {
      if (this.productRetailersName.includes("HelloBB")) {
        return "Active";
      }
      return "No";
    },
    target() {
      return this.$store.state.cookies.sourceApp ? "_self" : "_blank";
    },
    textPrice() {
      if (this.retailerIsHelloBB) {
        return this.$utils.formatMoney(this.product.productRetailers[0].price);
      }
      let priceArray = this.product.productRetailers?.map(function (item) {
        return item["price"];
      });
      let minValue = Math.min.apply(Math, priceArray);
      let maxValue = Math.max.apply(Math, priceArray);
      if (minValue === maxValue) return this.$utils.formatMoney(minValue);
      return (
        this.$utils.formatMoney(minValue) + " a " + this.$utils.formatMoney(maxValue)
      );
    },
    moreVendorsBesidesHelloBB() {
      return !(this.retailerIsHelloBB && this.product.productRetailers.length === 1);
    },
    notHighlightSecundaryBuyButton() {
      if (this.retailerIsHelloBB) return true;
      return !!this.isAuthenticated;
    },
    productRetailers() {
      return this.product.productRetailers?.filter(function (u) {
        return u.name !== "HelloBB";
      });
    },
  },
  watch: {
    product: {
      handler(newProduct) {
        if (newProduct?.productRetailers)
          this.checkedRetailer = newProduct?.productRetailers[0];
      },
      immediate: true,
    },
  },
  methods: {
    getPrice(retailer) {
      //it only works if the product has a single retailer
      if (this.selectedVariant) return this.selectedVariant.price;
      return retailer.price;
    },
    goToRetailer(retailer) {
      if (this.$utils.isApp()) {
        //no new tab
        window.location.href = retailer.url;
      } else {
        //new tab
        window.open(retailer.url, "_blank");
      }
      retailer.url
    },
    mixPanel(retailer) {
      let price = this.$utils.formatMoney(retailer.price);
      window.mixpanel.track("Visitar retailer", {
        "Retailer Name": retailer.name,
        "Retailer Price": price,
        "Referral name": this.product.name,
        "Referral URL": retailer.url,
        "Referral type": "Catàleg",
        "Referral list": window.location,
        "Buy Now?": false,
        "From Shared List?": false,
        "R visit source": "web",
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    sendMixPanelInfo() {
      if (this.list) {
        window.mixpanel.track("Comprar", {
          "Object name": this.product.name,
          "Selected Vendor": "HelloBB",
          "Retailer name": "HelloBB",
          "Object price": this.product.price,
          "List URL": "https://www.hellobb.net/guest/" + this.list.id,
          "Buyer role": "Propietari",
          Src: this.source === "Constructor" ? "fitxa constructor" : "fitxa catàleg",
          "Shopify BuyButton": "Si",
          "Sold by HelloBB": this.SoldByHelloBB,
          "Item Retailers": this.productRetailersName,
        });
      } else {
        window.mixpanel.track("Comprar", {
          "Object name": this.product.name,
          "Selected Vendor": "HelloBB",
          "Retailer name": "HelloBB",
          "Object price": this.product.price,
          //"List URL": "https://www.hellobb.net/guest/" + this.myList.id, - comentat perquè s'està comprant fora de la llista
          "Buyer role": "Desconegut",
          Src: "fitxa catàleg",
          "Shopify BuyButton": "Si",
          "Sold by HelloBB": this.SoldByHelloBB,
          "Item Retailers": this.productRetailersName,
        });
      }
    },
  },
};
</script>
